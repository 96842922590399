<template>
<vx-card :title="title">
    <template slot="actions">
        <div class="flex">
            <!-- <vs-button v-show="selected.length" color="success" @click="handleActive" class="mr-2">Activate</vs-button> -->
            <vs-button v-show="selected.length" @click="handlePdfDownload" class="mr-2" color="danger">Print</vs-button>
            <vs-input v-model="searchString" class="m-0" @keyup="handleFilter" placeholder="Search"></vs-input>
        </div>
    </template>
    <vs-table  multiple v-model="selected" stripe :data="registration" v-if="registration.length" :sst="true" @sort="handleSort">
        <template #thead>
            <!-- <vs-th class="flex justify-center">
                <vs-checkbox v-model="allCheck" @change="selectAll" />
            </vs-th> -->
            <vs-th sort-key="displayName">Name</vs-th>
            <vs-th sort-key="mobileNumber">Phone No</vs-th>
            <vs-th sort-key="passNumber">Pass Number</vs-th>
            <vs-th sort-key="productName">Pass Name</vs-th>
            <vs-th class="width1"></vs-th>
            <vs-th class="width1" sort-key="registrationPlate">Registration</vs-th>
            <vs-th class="" sort-key="transactionDate">Purchase Date</vs-th>
            <vs-th class="width1" sort-key="status">Status</vs-th>
            <!-- <vs-th class="width1"></vs-th> -->
        </template>
        <template :slot-scope="{ data }">
            <vs-tr v-for="(tr, index) in registration" :key="index" :data="tr">
                <!-- <vs-td>
                    <vs-checkbox :vs-value="tr.id" v-model="selected" @change="handleSelection" />
                </vs-td> -->
                <vs-td>{{ tr.displayName }}</vs-td>
                <vs-td>{{ tr.mobileNumber }}</vs-td>
                <vs-td>
                  {{ tr.passNumber }}
                  <vs-commnet-icon :registrationId="tr.id"></vs-commnet-icon>
                </vs-td>
                <vs-td>{{ tr.productName }}</vs-td>
                <vs-td>
                    <vs-avatar :color="tr.statusColor ? tr.statusColor : '#ccc'" size="large" icon-pack="feather" icon="icon-credit-card" />
                </vs-td>
                <vs-td>
                    {{ tr.registrationPlate }}
                </vs-td>
                <vs-td>{{ tr.transactionDate | formatLongDate }}</vs-td>
                <vs-td>
                    <vs-chip color="success">{{ tr.status }}</vs-chip>
                </vs-td>
                <!-- <vs-td>
                    <vs-button @click="editSelected(tr)">View Details</vs-button>
                </vs-td> -->
            </vs-tr>
        </template>
    </vs-table>
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
    <checkRegistration v-if="openAddWindow" :selectedReg="selectedReg" :regType="title" :openAddWindow="openAddWindow" @loadVehicles="loadVehicles" @onClose="handleClose"></checkRegistration>
</vx-card>
</template>

<script>
import {
    relativeTimeThreshold
} from 'moment';
import {
    carData
} from "../../store/api/car";
import checkRegistration from "./checkRegistration";
import VsCommnetIcon from "../../components/vs-comment-icon/VsCommentIcon.vue";

export default {
    data() {
        return {
            allCheck: false,
            selectedReg: undefined,
            selected: [],
            select: [],
            intervalId: null,
            openAddWindow: false,
            registration: [],
            title: "Processing Season Passes",
            searchString: "",
            downloadFile: null,
            noData: {
                title: "No Records Found"
            }
        };
    },
    components: {
        checkRegistration,
        VsCommnetIcon
    },

    async created() {
        await this.loadVehicles();
    },

    methods: {
        async loadVehicles() {
            this.selected = [];
            this.registration = await carData.SearchProcessingCarRegistration(
                this.searchString
            );
        },

        async handleFilter() {
            this.registration = [];
            if (this.intervalId) {
              clearTimeout(this.intervalId)
            }
            this.intervalId = setTimeout(async() => {
                await this.loadVehicles();
            }, 800);
        },

        handleSelection() {
            this.allCheck =
                this.selected.length === this.registration.length ? true : false;
        },

        async selectAll() {
            this.selected = this.allCheck ?
                await this.registration.map(ele => ele.id) : [];
        },

        async handleActive() {
            let apiResult = await carData.ActiveCarRegistration(this.selected);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });

            await this.loadVehicles();
        },

        async handlePdfDownload() {
            const selectedItem = await this.selected.map(ele => ele.id)

            this.downloadFile = await carData.downloadAllSeasonPass(selectedItem);
            if (this.downloadFile) {
                this.$vs.dialog({
                    color: this.colorAlert,
                    title: 'Download File',
                    text: 'Your file is now ready to download.',
                    "accept-text": "Download & Accept",
                    accept: this.acceptAlert
                })
                return;
            }

            this.$vs.notify({
                title: "Error",
                text: "Couldn't complete the generation of the file, please try again.",
                color: "danger",
                position: "top-center"
            });
            return;
        },

        async acceptAlert() {
            if (this.downloadFile) {
                const selectedItem = await this.selected.map(ele => ele.id)

                let apiResult = await carData.ActiveCarRegistration(selectedItem);
                window.open(this.downloadFile.url, '_blank').focus();

                await this.loadVehicles();
            }
        },

        handleClose() {
            if (this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
                this.loadVehicles();
            }
        },

        editSelected(tr) {
            this.selectedReg = tr;
            if (!this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
            }
        },

        handleSort(key, active) {
            this.registration = _.orderBy(this.registration, key, active);
        },
    }
};
</script>
